import axios from "axios";

const baseURL = "https://api.cccv.to";

const Authorization = process.env.REACT_APP_ACCESS_TOKEN;
const refreshtoken = process.env.REACT_APP_REFRESH_TOKEN;

export const getApi = (url) => {
    const completeURL = baseURL + url;
    return axios
        .get(completeURL, {
            headers: {
                Authorization,
                refreshtoken,
            },
        })
        .then((response) => response.data);
};

export const getAllApi = (urlList) => {
    const completeURLList = urlList.map(url => baseURL + url);
    const reqList = completeURLList.map(url => axios.get(url));
    return axios
        .all(reqList, {
            headers: {
                Authorization,
                refreshtoken,
            },
        })
        .then(axios.spread((...responses) => responses.map(res => res.data)));
};