import React, { Component } from "react";
import Card from "../components/Card";
import Dropzone from "react-dropzone-uploader";
import Editor from "../components/Editor";
import classNames from "classnames";
import "react-dropzone-uploader/dist/styles.css";
import "./Mint.scss";

const creatorPage = {
    user_id: "MWXo31wod19vyfPVuqZr",
    pageId: "musicspray",
    name: "사운드펍",
    profileImgUrl:
        "https://cccv-to.s3.ap-northeast-2.amazonaws.com/files/profile/MWXo31wod19vyfPVuqZr_KXduC7RN42",
    isVerified: true,
};

class Mint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "title",
            edition: 1,
            creator: "soundpub",
            royalties: 10,
            description: "",
            previewImage: null,
            toggleMobilePreview: false,
        };
    }

    handleChangeStatus = ({ meta }, status) => {
        if (meta.type.indexOf("image") !== -1) {
            this.setState({
                previewImage: meta.previewUrl,
            });
        }
        if (status === "removed") {
            this.setState({
                previewImage: null,
            });
        }
    };

    handleSubmit = (files, allFiles) => {
        // console.log(files.map((f) => f.meta));
        allFiles.forEach((f) => f.remove());
    };

    handleDetails = (description) => {
        this.setState({
            description,
        });
    };

    handleTextInputs = (event, target) => {
        this.setState({
            [target]: event.target.value,
        });
    };

    handleMobilePreview = (event) => {
        event.preventDefault();
        const { toggleMobilePreview } = this.state;
        this.setState({
            toggleMobilePreview: !toggleMobilePreview,
        });
    };

    closeMobilePreview = (event) => {
        // IE polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.msMatchesSelector ||
                Element.prototype.webkitMatchesSelector;
        }

        if (!Element.prototype.closest) {
            Element.prototype.closest = function (s) {
                var el = this;

                do {
                    if (el.matches(s)) return el;
                    el = el.parentElement || el.parentNode;
                } while (el !== null && el.nodeType === 1);
                return null;
            };
        }
        // IE polyfill end

        const { toggleMobilePreview } = this.state;
        if (toggleMobilePreview && !event.target.closest("section.preview")) {
            this.setState({
                toggleMobilePreview: false,
            });
        }
    };

    render() {
        const {
            title,
            edition,
            creator,
            royalties,
            description,
            previewImage,
            toggleMobilePreview,
        } = this.state;
        return (
            <div className="Mint" onClick={this.closeMobilePreview}>
                <section className="main-input">
                    <h1>NFT 발행하기</h1>
                    <div className="input-box">
                        <div className="title">작품 업로드</div>
                        <div className="sub-title"></div>
                        <div className="input">
                            <Dropzone
                                maxFiles={1}
                                onChangeStatus={this.handleChangeStatus}
                                styles={{
                                    dropzone: {
                                        minHeight: 200,
                                        maxHeight: 250,
                                        width: "100%",
                                    },
                                }}
                                onSubmit={this.handleSubmit}
                            />
                        </div>
                    </div>
                    <div className="input-box">
                        <div className="title">작품 제목</div>
                        <div className="sub-title">
                            작품의 제목을 입력해주세요
                        </div>
                        <div className="input">
                            <input
                                type="text"
                                className="text-input"
                                value={title}
                                onChange={(event) =>
                                    this.handleTextInputs(event, "title")
                                }
                                placeholder="50자 이내로 적어주세요"
                            />
                        </div>
                    </div>
                    <div className="input-box">
                        <div className="title">에디션</div>
                        <div className="sub-title">
                            발행하는 작품의 수량으로, 최대 1,000개까지
                            가능합니다.
                        </div>
                        <div className="input">
                            <input
                                type="text"
                                className="text-input"
                                value={edition}
                                onChange={(event) =>
                                    this.handleTextInputs(event, "edition")
                                }
                            />
                        </div>
                    </div>
                    <div className="input-box">
                        <div className="title">창작자</div>
                        <div className="sub-title">
                            작품의 발행인과 창작자가 다르다면 수정해주세요!
                        </div>
                        <div className="input">
                            <input
                                type="text"
                                className="text-input"
                                value={creator}
                                onChange={(event) =>
                                    this.handleTextInputs(event, "creator")
                                }
                            />
                        </div>
                    </div>
                    <div className="input-box">
                        <div className="title">인세(%)</div>
                        <div className="sub-title">
                            CCCV.NFT에서 재거래될 때 마다 최초 발행자가 취득할
                            수 있는 수익의 비율을 말합니다.
                        </div>
                        <div className="input">
                            <input
                                type="text"
                                className="text-input"
                                placeholder="인세비율을 숫자로 입력해주세요"
                                value={royalties}
                                onChange={(event) =>
                                    this.handleTextInputs(event, "royalties")
                                }
                            />
                        </div>
                    </div>
                    <div className="input-box description">
                        <div className="title">작품 설명</div>
                        <div className="sub-title"></div>
                        <div className="input">
                            <Editor handleText={this.handleDetails} />
                        </div>
                        <div className="mobile-preview">
                            <button onClick={this.handleMobilePreview}>
                                {toggleMobilePreview ? "닫기" : "미리보기"}
                            </button>
                        </div>
                    </div>
                </section>
                <section
                    className={classNames("preview", {
                        "mobile-view": toggleMobilePreview,
                    })}
                >
                    <Card
                        _id="0"
                        aergoNftTokenId="0"
                        creatorPage={creatorPage}
                        image={previewImage}
                        name={title}
                        creator_name={creator}
                        edition={edition}
                    />
                </section>
            </div>
        );
    }
}

export default Mint;
