import React, { Component } from "react";
import { getApi, getAllApi } from "../util/api";
import header from "../assets/image/header.jpg";
import mobileHeader from "../assets/image/mobile-header.jpg";
import Card from "../components/Card";
import { currentSaleCardsList, otherGenreCardsList, recommendedCardsList } from "../util/tokens";
import "./Home.scss";

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentSaleCards: [],
			otherGenreCards: [],
			recommendedCards: [],
		};
	}

	async componentDidMount() {
		/**
		Get all NFT card created by soundpub

		try {
			const result = await getApi('/nft/v2/user/nft.soundpub/created?limit=100');
			console.log(result);
		} catch(err) {
			console.error('/nft/v2/user/nft.soundpub/created', err);
		}

		 */
		
		const currentSaleCards = await getAllApi(currentSaleCardsList);
		const otherGenreCards = await getAllApi(otherGenreCardsList);
		const recommendedCards = await getAllApi(recommendedCardsList);

		this.setState({
			currentSaleCards,
			otherGenreCards,
			recommendedCards,
		});
	}

	openExternalUrl = (url) => {
		const baseURL = "https://cccv.to/nft/";
		window.open(baseURL + url);
	};

	render() {
		const { currentSaleCards, otherGenreCards, recommendedCards } =
			this.state;
		return (
			<div className="Home">
				<main>
					<header className="image-wrapper main-header">
						<img className="desktop" src={header} />
						<img className="mobile" src={mobileHeader} />
						<div className="header-title">
							<p>NFT 디지털 음반</p>
							<p>powered by SoundPub</p>
						</div>
					</header>
					<section className="NFT-card-deck-wrapper current-sale">
						<div className="deck-title">
							[K-POP] 판매중인 케이팝 NFT
						</div>
						<section className="NFT-card-deck">
							{currentSaleCards.length &&
								currentSaleCards.map(card => (
									<Card
										key={card._id}
										_id={card._id}
										aergoNftTokenId={card.aergoNftTokenId}
										creatorPage={card.creatorPage}
										likes={card.likes}
										image={card.image}
										name={card.name}
										creator_name={card.creator_name}
										edition={card.edition}
										openExternalUrl={this.openExternalUrl}
									/>
								))}
						</section>
					</section>
					<section className="NFT-card-deck-wrapper other-genre">
						<div className="deck-title">
							[다른 장르] 판매중인 다른 장르 NFT
						</div>
						<section className="NFT-card-deck">
							{otherGenreCards.length &&
								otherGenreCards.map(card => (
									<Card
										key={card._id}
										_id={card._id}
										aergoNftTokenId={card.aergoNftTokenId}
										creatorPage={card.creatorPage}
										likes={card.likes}
										image={card.image}
										name={card.name}
										creator_name={card.creator_name}
										edition={card.edition}
										openExternalUrl={this.openExternalUrl}
									/>
								))}
						</section>
					</section>
					<section className="NFT-card-deck-wrapper recommended">
						<div className="deck-title">
							[추천 NFT] 판매중인 운영자 추천 NFT
						</div>
						<section className="NFT-card-deck">
							{recommendedCards.length &&
								recommendedCards.map(card => (
									<Card
										key={card._id}
										_id={card._id}
										aergoNftTokenId={card.aergoNftTokenId}
										creatorPage={card.creatorPage}
										likes={card.likes}
										image={card.image}
										name={card.name}
										creator_name={card.creator_name}
										edition={card.edition}
										openExternalUrl={this.openExternalUrl}
									/>
								))}
						</section>
					</section>
				</main>
			</div>
		);
	}
}

export default Home;
