const prefix = "/nft/token/";

const currentSaleCardsData = [
    "ad77e15c40c1c4c0193c1be765d871933_2",
    "a0e452aaf6c1445d9b8ab6fb98f404632_2",
    "a711f03e728b944eab52de19406816747_2",
    "ae622bf182aee42c7b5f48e610ec99e3a_2"
]

const otherGenreCardsData = [
    "a3f1d077ae2ea4052a704884ef6ab8b21_2",
    "aac6bcbef5d4840a7a279a065c8913ad3_2",
    "a63301b52ae14405a83b5bd6107d436cd_2",
    "a23aede6446974f5dad8542270617c303_1",
    "a01bde21aed264f01ba3988100bd730b2_1",
    "a219415c2319d4880bd8b7052d6c7b9ff_2",
    "abd3d868c58254d3ea96be199279bb047_1",
    "a9c1a5de0cb6347218c48df4f4094fba2_1"
];

const recommendedCardsData = [
    "ae622bf182aee42c7b5f48e610ec99e3a_2",
    "a01bde21aed264f01ba3988100bd730b2_1",
    "a63301b52ae14405a83b5bd6107d436cd_2",
    "a9c1a5de0cb6347218c48df4f4094fba2_1"
];

export const currentSaleCardsList = currentSaleCardsData.map(card => `${prefix}${card}`);
export const otherGenreCardsList = otherGenreCardsData.map(card => `${prefix}${card}`);
export const recommendedCardsList = recommendedCardsData.map(card => `${prefix}${card}`);