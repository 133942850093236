import "./Card.scss"
import graySquare from "../assets/image/gray_square.png";

const heartImageSource = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAMAAABhEH5lAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAA5UExURUdwTPBGcPBGcPVNdvBGcPBHb/BHcPBGb/FHcfJMdPBGcP////BIcPBGb/9MmfBGcPZJdu9GcO9GbwFOJLIAAAASdFJOUwDNsxn1l8DwYBPaAW/mBYYcUP+woyoAAABlSURBVBjTpZBLDsAgCETxg4iKbb3/YesnqSFdNZ0NyctAZgD4oRgooWNMFOIi2IZI5rB5eJpS6ShoZDoijVoFEE18P2bfi5dGM4aymToQm03SubLy4wtuV5pJfMm7ZeZDBN2Xx9wbgwqtQSlaSwAAAABJRU5ErkJggg==';
const heartSrcSet = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAA2UExURUdwTPBGcPBHcfBGcPdPePRIcv9YjfBHcPBGb/BGb/BHcPBHcPFHcPJIcfBHcPBGb/FIcO9Gb1rHWJwAAAARdFJOUwCzdtoQIgX75caYTWo8ifJWTL/N2QAAAL1JREFUOMvllNsSgyAMRAkCCVfN//9sK9KKVaOPznTfMnMmCQuLUv8gqz0heW3nwiSYi2I3iCvIi7A4pcdWcDArYzyv8tAVw7eZ65kf0adXYUHQdkYJ4lwhLTIcKuRliCpEMsR1dbyAqguDzKCbIbizU5AhX6EsQ2lxU1xqbPeSrr28ecHKjqfnz+uDimeG6v5pTnjNvHsdTMS0S8LOCMr7vJiwHQnmMFa5s2KIp+mLbSYlJ4U0AhFM7vm/yQu2TClbYusF7gAAAABJRU5ErkJggg==, data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2BAMAAAB+a3fuAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUdwTPBHb/BGcPBGb/FIcfNJcvRJc/BGb/BHcP9Rg/BHcPBGcPBGb/FHcfBIce9GbzN2JM0AAAAPdFJOUwCw3O43JBX3dAmLwtNcT5MaMx8AAAEaSURBVDjLY2AY8SBCxlx73QQQy2uTsfYSZCnv/yBgAZS8BGbpIqQS/0OAHUMMlHUUJsWqDxX5v7Yeyvj8ACoX/B8T/INIseljkfvuAJZj/I8NaILl/LHK/QDL7ccq9xnkW9b/2MEFoBwHDjkBnE75/98AKMeDQ+4nUI4Zh9xXoBw7DrkvBPQx4ZD7RsCd+PzHgkOuARSe+tjlEkBy67FK/QbHQzpO7zEwcGGV2wCW4zyPLfoCIAnGHYvcX2g648DhOzCQx5D6kwCTm4ghpwZP85zoGj8lIDIEusZjyBmpH0Xq4wRkORZ75PTegJo5E5HkjqDn3EdwqQqMXM25HGZZAGaWZ4XE1UcHbOUB63KcUsBM2m1+KmAIFnIAjQfCjQwu/VQAAAAASUVORK5CYII=';

export default function Card({
    _id, aergoNftTokenId, creatorPage, likes, image, name, creator_name, edition, openExternalUrl = () => {}
}) {
    return (
        <div className="NFT-card" key={_id} onClick={() => openExternalUrl(aergoNftTokenId)}>
            <div className="creator-section">
                <div className="logo-section">
                    <div className="creator-image image-wrapper">
                        <img src={creatorPage.profileImgUrl} />
                    </div>
                    <div>{creatorPage.name}</div>
                </div>
                { likes &&
                    <div className="like-section">
                        <img src={heartImageSource} srcSet={heartSrcSet} className="like-heart" />
                        {likes}
                    </div>
                }
            </div>
            <div className="content-section">
                <div className="image-wrapper">
                    <img className="content-image" 
                        src={image ? image : graySquare} />
                </div>
                <p className="title">{name}</p>
                <p className="created-wrapper">
                    <span className="created-title">Created by</span>
                    <span className="created-name">{creator_name}</span>
                </p>
            </div>
            <div className="footer-section">
                <p className="edition">Edition</p>
                <p className="number"><span>#</span><span>{edition}</span></p>
            </div>
        </div>
    )
}