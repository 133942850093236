import React from "react";
import { Link } from "react-router-dom";
import "./Header.scss";

function Header() {
    return (
        <header className="App-header">
            <div className="flex-box">
                <div className="image-wrapper logo-image">
                    <Link to="/">
                        <img src="https://cccv-to.s3.ap-northeast-2.amazonaws.com/files/profile/MWXo31wod19vyfPVuqZr_KXduC7RN42" className="App-logo" alt="logo" />
                    </Link>
                </div>
                <input type="text" placeholder="찾고 계신 NFT 음원, 아티스트가 있나요?" />
            </div>
            <Link to="/minting">
                <button className="minting-button">NFT 발행</button>
            </Link>
        </header>
    );
}

export default Header;
